'use client';

import { IBlockTypeDetail } from 'core/types/Models/blockType';
import { IBlock, IBlockDetail } from 'core/types/Models/linkType';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useEffect, useState } from 'react';
import { useIsServerSide } from 'core/hooks/useIsServerSide';
import { NextRequest, NextResponse } from 'next/server';
import { IThemeColors, IThemeColorsString } from 'core/types/Models/templateType';

export const isBrowser = () => typeof window !== 'undefined' || !!process?.browser;

export const convertPersianNumberToEnglish = (s: string | number, number?: boolean) => {
  const elem = s
    ?.toString()
    .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')
    .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '')
    .replace(/\D/g, '');
  if (number) return +elem;
  return elem;
};

export const convertPToENumber = (s: string | number, number?: boolean) => {
  const elem = s
    ?.toString()
    .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')
    .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '');
  if (number) return +elem;
  return elem;
};

export const convertAllPropertyToEnIntegerNumber = (values: any) => {
  const newValues: any = {};
  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key)) {
      const element = values[key];

      if (element && !isNaN(element)) {
        newValues[key] = convertPersianNumberToEnglish(element, true);
      } else if (Array.isArray(element)) {
        newValues[key] = element.map((item) => {
          convertPersianNumberToEnglish(item);
          if (item && !isNaN(item)) return convertPersianNumberToEnglish(item, true);
          else return convertPersianNumberToEnglish(item);
        });
      } else {
        newValues[key] = convertPersianNumberToEnglish(element);
      }
    }
  }

  return newValues;
};

export const convertAllPropertyToEnStringNumber = (values: any, number = false) => {
  const newValues: any = {};
  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key)) {
      const element = values[key];

      if (Array.isArray(element)) {
        newValues[key] = element.map((item) => {
          convertPToENumber(item, number);
        });
      } else {
        newValues[key] = convertPToENumber(element, number);
      }
    }
  }

  return newValues;
};

export const septateStringWithDash = (text = '') => {
  return text.replace(/\s+/g, '-').toLowerCase();
};

export const overrideJoin = (items: any[], overrideAttribute: any) => {
  return items.map((item) => ObjectByString(item, overrideAttribute));
};

export const ObjectByString = (o: any, s: any) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const getBlockLink = (block: IBlockDetail): { blockLink: string } => {
  const isPhone = block?.type?.en_name.toLowerCase().includes('mobile') || block?.type?.en_name.toLowerCase().includes('phone');
  const isSms = block?.type?.en_name.toLowerCase().includes('sms');
  const isEmail = block?.type?.en_name.toLowerCase().includes('email');

  const blockLink = isPhone ? 'tel:' : isSms ? 'sms:' : isEmail ? 'mailto:' : '';

  return { blockLink };
};

// get block name
export const getBlockTypeDetail = (block: IBlock): IBlockTypeDetail => {
  switch (block.type) {
    case 'App\\Models\\LinkApp\\ExternalLink':
      return { title: 'لینک‌ها', name: 'externalLink' };

    case 'App\\Models\\LinkApp\\Messenger':
      return { title: 'پیام رسان ها', name: 'messenger' };

    case 'App\\Models\\LinkApp\\Navigation':
      return { title: 'آدرس‌های من', name: 'navigation' };

    case 'App\\Models\\LinkApp\\SocialMedia':
      return { title: 'شبکه‌های اجتماعی', name: 'socialMedia' };

    case 'App\\Models\\LinkApp\\FAQ':
      return { title: 'سوالات متداول', name: 'faq' };

    case 'App\\Models\\LinkApp\\Connection':
      return { title: 'راه‌های ارتباطی', name: 'connection' };

    case 'App\\Models\\LinkApp\\Divider':
      return { title: 'جداکننده‌ها', name: 'divider' };

    case 'App\\Models\\LinkApp\\PaymentLink':
      return { title: 'لینک‌های پرداخت', name: 'paymentLink' };
    default:
      return { title: '', name: '' };
  }
};

export const IMAGE_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL;

export const separator = (input: string | number, seperater = ',', seperateLength = 3) => {
  if (input === null || input === undefined) return '';
  input = input.toString();
  let result = '';
  let count = 0;
  for (let i = input.length - 1; i > -1; i--) {
    if (count === seperateLength) {
      result = seperater + result;
      count = 0;
    }
    result = input.charAt(i) + result;
    count++;
  }
  return result;
};

// get css variable
export const getCssVariable = (variable: string) => {
  const { theme } = useSelector((store: RootState) => store.settingThemeStore);
  const { isServerSide } = useIsServerSide();

  const rootEl = !isServerSide && document.querySelector(':root');
  const [color, setColor] = useState('');

  useEffect(() => {
    rootEl && setColor(getComputedStyle(rootEl).getPropertyValue(variable));
  }, [rootEl, theme]);

  return color;
};

export const convertTimeStampToCountDown = (endTimeStamp: number, startTimeStamp?: number, callBack?: (val: any, percentage?: number) => void) => {
  const countDownDate = new Date(endTimeStamp * 1000).getTime();

  const interval = setInterval(function () {
    const now = new Date().getTime();

    const distance = countDownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
      clearInterval(interval);
      return callBack?.('EXPIRED', 0);
    }

    const range = endTimeStamp * 1000 - (startTimeStamp || 0) * 1000;
    const diff = Math.max(0, endTimeStamp * 1000 - new Date().getTime());
    const percentage = (100 * diff) / range;

    // console.log('distance', distance);

    // callBack?.((days ? days + 'd ' : '') + (hours || days ? hours + 'h ' : '') + (minutes || hours ? minutes + 'm ' : '') + seconds + 's ', percentage);
    callBack?.((days ? days + ' : ' : '') + (hours || days ? hours + ' : ' : '') + (minutes || hours ? minutes + ' : ' : '00 : ') + seconds, percentage);
    // callBack?.(days ? days + ' days' : hours || minutes ? ' 1 day' : '', percentage);
  }, 1000);
};

export const stringToBoolean = (string: string) => {
  string = string + '';
  switch (string?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case '':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return undefined;
  }
};

export const getRequestRoute = (referer: string) => `/${referer.split('/').slice(3).join('/')}`;

export const useRouteMiddleware = (req: NextRequest, destinationUrls: string[], requestRoute: string) => {
  // for (let i=0)
  for (let j = 0; destinationUrls.length >= j; j++) {
    if (req.nextUrl.pathname === requestRoute) {
      console.log(req.nextUrl.pathname, destinationUrls[j], j, 'ooo444');
      return NextResponse.rewrite(new URL(destinationUrls[j], req.url));
    }
  }
};

export const blockBackgroundImageConfig = (image?: string, backgroundSize: 'contain' | '100%' = 'contain') => {
  return {
    object: { backgroundImage: `url(${image})`, backgroundRepeat: 'repeat-y', backgroundPosition: 'center', backgroundSize },
    cssText: `background-image: url(${image});` + 'background-repeat: repeat-y;' + 'background-position: center;' + `background-size: ${backgroundSize};`,
  };
};

// theme color

export const setRootColor = (colors: IThemeColors) => {
  Object.keys(colors).map((color) => document.documentElement.style.setProperty(`--${color}`, colors[color as IThemeColorsString]));
};
