import { combineReducers } from 'redux';

import settingThemeStore from './slice/setting/settingThemeSlice';
import linkStore from './slice/link/linkSlice';
import mainInfoStore from './slice/mainInfo/mainInfoSlice';
import paymentPayPayloadStore from './slice/paymentPay/paymentPaySlice';
import templateStore from 'core/store/slice/template/templateSlice';
import siteConfigStore from 'core/store/slice/config/siteConfigSlice';

const rootReducer = combineReducers({ settingThemeStore, linkStore, mainInfoStore, paymentPayPayloadStore, templateStore, siteConfigStore });

export default rootReducer;
