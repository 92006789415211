import { createSlice } from '@reduxjs/toolkit';
const initialState: { header: Headers | null } = { header: null };

export const siteConfigSlice = createSlice({
  name: 'siteConfig',
  initialState: initialState,
  reducers: {
    setSiteHeader: (state, { payload }: { payload: Headers }) => {
      state.header = payload;
    },
  },
});

export const { setSiteHeader } = siteConfigSlice.actions;
export default siteConfigSlice.reducer;
