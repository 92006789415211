import { createSlice } from '@reduxjs/toolkit';
import { ILinkDetail, ILinkList } from 'core/types/Models/linkType';

const initialState: { linkDetail: ILinkDetail | null; linkList: ILinkList | null } = {
  linkDetail: null,
  linkList: null,
};

export const linkSlice = createSlice({
  name: 'link',
  initialState: initialState,
  reducers: {
    setLinkDetail: (state, { payload }) => {
      state.linkDetail = payload;
    },
    setLinkList: (state, { payload }) => {
      state.linkList = payload;
    },
  },
});

export const { setLinkDetail, setLinkList } = linkSlice.actions;
export default linkSlice.reducer;
