import { createSlice } from '@reduxjs/toolkit';
import { ITemplate, IThemeColors } from 'core/types/Models/templateType';
import { setRootColor } from 'core/utils/default';

const initialState: {
  templateDetail: ITemplate | null;
} = {
  templateDetail: null,
};

export const templateSlice = createSlice({
  name: 'template',
  initialState: initialState,
  reducers: {
    setTemplateDetail: (state, { payload }: { payload: ITemplate }) => {
      const themeColors: IThemeColors = { ...payload.styles.colors, default_color: payload?.background?.default_color };

      setRootColor(themeColors);

      state.templateDetail = payload;
    },
  },
});

export const { setTemplateDetail } = templateSlice.actions;
export default templateSlice.reducer;
