import { createSlice } from '@reduxjs/toolkit';

const initialState: { paymentPayPayload: { first_name: string; last_name: string; username: string; amount: number; paymentLinkId: number; isSuccess: boolean } } = {
  paymentPayPayload: { first_name: '', last_name: '', username: '', amount: 0, paymentLinkId: 0, isSuccess: false },
};

export const paymentPaySlice = createSlice({
  name: 'paymentPay',
  initialState: initialState,
  reducers: {
    setPaymentPayPayload: (state, { payload }) => {
      state.paymentPayPayload = payload;
    },
  },
});

export const { setPaymentPayPayload } = paymentPaySlice.actions;
export default paymentPaySlice.reducer;
