import { createSlice } from '@reduxjs/toolkit';

const initialState: { username: string; apiCatcherFormErrors: any[] } = { username: '', apiCatcherFormErrors: [] };

export const mainInfoSlice = createSlice({
  name: 'mainInfo',
  initialState: initialState,
  reducers: {
    setUsername: (state, { payload }) => {
      state.username = payload;
    },
    setApiCatcherFormErrors: (state, { payload }) => {
      state.apiCatcherFormErrors = payload;
    },
  },
});

export const { setUsername, setApiCatcherFormErrors } = mainInfoSlice.actions;
export default mainInfoSlice.reducer;
