'use client';

import { FC, ReactNode } from 'react';
import ReactQueryProviders from './react-query/ReactQueryProviders';
import ThemeProvider from './theme/ThemeProvider';
import Toast from './toast/Toast';

interface IndexProvider {
  children: ReactNode;
}

const IndexProvider: FC<IndexProvider> = ({ children }) => {
  return (
    <ReactQueryProviders>
      {/*<AntProvider>*/}
      <ThemeProvider>{children}</ThemeProvider>
      <Toast />
      {/*</AntProvider>*/}
    </ReactQueryProviders>
  );
};

export default IndexProvider;
