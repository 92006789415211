import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';
import { useState } from 'react';

const useQueryClientMaster = () => {
  const [queryClientMaster] = useState(
    new QueryClient({
      queryCache: new QueryCache({
        onError: (res: any, b) => {
          console.log(res, b, 444);
        },
      }),
      mutationCache: new MutationCache({
        onError: (res: any, b) => {
          console.log(res, b, 444);
        },
      }),
      defaultOptions: { queries: { refetchOnWindowFocus: false, keepPreviousData: true, retry: 1 } },
    })
  );

  return { queryClientMaster };
};

export default useQueryClientMaster;
